@import '../../../theme/colors';

input {
  color: #000;
  border: 2px solid #e6e6e6;
  padding: 0.5em 0.5em;
  font-size: 15px;
  letter-spacing: 0.4px;
  background-image:none;
  box-shadow: none;

  &:hover, &:focus {
    border: 2px solid $primary-color;
    outline: none;
  }
  &::placeholder {
    font-size: 15px;
  }
}

[data-ui~="dark"] input {
  background-color: #353535;
  color: #FFF;
  border: none;
}

select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0.5em 3.9em 0.5em 0.5em;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;

  // Stack above custom arrow
  z-index: 1;
  &::-ms-expand {
    display: none;
  }
}

.input-label {
  position: relative;
  margin-top: 10px;

  label {
    position: absolute;
    top: -10px;
    left: 6px;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 20px;
    padding: 0 0.2em;
    background-color: #fff;
  }
}

.datalistWrapper {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  margin-top: 10px;
  border: 2px solid #e6e6e6;


  label {
    position: absolute;
    top: -10px;
    left: 6px;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 20px;
    padding: 0 0.2em;
    background-color: #fff;
  }

  &:hover, &:focus {
    border: 2px solid $primary-color;
    outline: none;
  }

  > input {
    border: none;

    &:hover, &:focus {
      border: none;
    }
  }
}

.customSelect {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  margin-top: 10px;

  label {
    position: absolute;
    top: -10px;
    left: 6px;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 20px;
    padding: 0 0.2em;
    background-color: #fff;
  }

  &:hover, &:focus {
    border: 2px solid $primary-color;
    outline: none;
  }

  select,
  &::after {
    grid-area: select;
  }

  min-width: 15ch;

  border: 2px solid #e6e6e6;

  color: #000;
  font-size: 15px;
  line-height: 1.5em;
  cursor: pointer;

  // Optional styles
  // remove for transparency
  background-color: #fff;

  // Custom arrow
  &::after {
    position: absolute;
    right: 6px;
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: #353535;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }

  &.open::after {
    transform: rotate(180deg);
  }
}

select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid $primary-color;
  border-radius: inherit;
}

[data-ui~="dark"] .customSelect {
  background-color: #353535;
  border: none;
  &::after {
    background-color: #fff;
  }
}

.customCheckbox {
  display: flex; //for a proper box rendering
  [type="checkbox"]:checked ~ label {
    border: 2px solid $primary-color;
    background: $primary-color;
    color: #fff;
  }

  label {
      border: 2px solid #e6e6e6;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      color: #000;
      cursor: pointer;

      &:hover {
        border: 2px solid $primary-color;
        background: $primary-color;
        color: #fff;
      }
  }

  .visually-hidden {
      position: absolute;
      left: -100vw;
  }
}

[data-ui~="dark"] select {
  background-color: #353535;
  color: #FFF !important;
}
