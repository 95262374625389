@use "sass:map";
@use "sass:color";

@import '../../../theme/colors';
@import '../../../theme/brandItems';

@mixin button($background, $font, $border) {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;

  background-color: $background;
  color: $font;
  border: 2px solid $border;

  cursor: pointer;

  &:hover, &:focus {
    @if $background != transparent {
      $lighten: -0.3;
      background-color: color.adjust($background, $lightness: $lighten * -100%);
      border-color: color.adjust($border, $lightness: $lighten * -100%);
      color: color.adjust($font, $lightness: $lighten * -100%);
    }
    @else {
      $lighten: -0.5;
      background-color: color.adjust($background, $alpha: $lighten);
      border-color: color.adjust($border, $alpha: $lighten);
      color: color.adjust($font, $alpha: $lighten);
    }
    transition: all 0.3s;
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    background-color: desaturate($color: $background, $amount: 60%);
    border-color: desaturate($color: $border-color, $amount: 60%);
    color: desaturate($color: $font, $amount: 60%);
    cursor: not-allowed;
  }
}

button[data-ui~="small"] {
  padding: 0.5em !important;
}

button {
  @include button($primary-color, $primary-font-color, $primary-color);
}

button[data-ui~="secondary"] {
  @include button(transparent, $primary-color, $primary-color);
}

button[data-ui~="accent1"] {
  @include button($accent1-color, $accent1-font-color, $accent1-color);

  &[data-ui~="secondary"] {
    @include button(transparent, $accent1-color, $accent1-color);
  }
}

button[data-ui~="accent2"] {
  @include button($accent2-color, $accent2-font-color, $accent2-color);

  &[data-ui~="secondary"] {
    @include button(transparent, $accent2-color, $accent2-color);
  }
}

button[data-ui~="warn"] {
  @include button($warn-color, $warn-font-color, $warn-color);

  &[data-ui~="secondary"] {
    @include button(#fff, $warn-color, $warn-color);
  }
}

[data-ui~="primary"]:not([data-ui~="secondary"]) > button {
  @include button(transparent, $primary-font-color, $primary-font-color);
}

[data-ui~="accent1"]:not([data-ui~="secondary"]) > button {
  @include button(transparent, $accent1-font-color, $accent1-font-color);
}

[data-ui~="accent2"]:not([data-ui~="secondary"]) > button {
  @include button(transparent, $accent2-font-color, $accent2-font-color);
}

[data-ui~="warn"]:not([data-ui~="secondary"]) > button {
  @include button(transparent, $warn-font-color, $warn-font-color);
}
