// base colors:
$white: #FFF;
$black: #000;
$cyan: #009fe4;
$green: #00bb7e;
$yellow: #ffee00;
$orange: #ff7f41;
$magenta: #e6007c;
$red: #f24726;
$purple: #7d55c7;
$inactiveGrey: #999999;
$lightGrey: #e6e6e6;

$dark: #1a1a1a;
$light: #f6f6f6;

// gradients:
$purple-cyan-gradient: linear-gradient(90deg, $purple 0%, $cyan 100%);
$cyan-green-gradient: linear-gradient(90deg, $cyan 0%, $green 100%);
$green-yellow-gradient: linear-gradient(90deg, $green 0%, $yellow 100%);
$yellow-orange-gradient: linear-gradient(90deg, $yellow 0%, $orange 100%);
$orange-magenta-gradient: linear-gradient(90deg, $orange 0%, $magenta 100%);
$magenta-purple-gradient: linear-gradient(90deg, $magenta 0%, $purple 100%);

// font
$font-family: Aktiv Grotesk, Arial, Sans-Serif;
$font-size: 15px;
$line-height: 24px;
$letter-spacing: .4px;

// further typography details: https://identitynet.covestro.com/typography.php

