[data-ui~="vertical"] > section, section {
  margin-top: 0;
  margin-right: 1em;
  margin-bottom: 0em;
  margin-left: 1em;
  padding: 1em;
  overflow: auto;

  &:first-of-type {
    margin-top: 1em;
    margin-left: 1em;
  }
}

[data-ui~="horizontal"] > section {
  margin-top: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  padding: 1em;

  &:first-of-type {
    margin-top: 1em;
    margin-left: 1em;
  }
}

/* If multilevel sections shall distributed equally: */

section[data-ui~="flatted"] {
  padding: 0;
}

section[data-ui~="grouped"] {
  padding: 0;
}


[data-ui~="vertical"] > section+section[data-ui~="vertical"][data-ui~="flatted"] > section {
  margin-top: 0;
}

[data-ui~="horizontal"] > section+section[data-ui~="horizontal"][data-ui~="flatted"] > section {
  margin-left: 0;
}

[data-ui~="vertical"] > section[data-ui~="flatted"][data-ui~="vertical"] {
  margin-bottom: 0;
}

[data-ui~="horizontal"] > section[data-ui~="flatted"][data-ui~="horizontal"] {
  margin-right: 0;
}

[data-ui~="horizontal"] > section[data-ui~="flatted"][data-ui~="vertical"] section {
  margin-left: 0;
  margin-right: 0;
}

[data-ui~="vertical"] > section[data-ui~="flatted"][data-ui~="horizontal"] section {
  margin-top: 0;
  margin-bottom: 0;
}
