@import "~normalize-scss/sass/normalize/import-now";

@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import '../../components/node_modules/@angular/cdk/overlay-prebuilt.css';

@import 'brandItems';
@import 'colors';
@import '../components/atoms/button/button';
@import '../components/atoms/input/input';
@import '../components/molecules/section/section';

html, body, app-root {
  height: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  background-color: $background-color;
  color: $font-color;

  &[data-ui~="dark-mode"] {
    background-color: $dark;
  }
}

input {
  box-sizing: border-box;
}

a {
  color: $primary-color;
  text-decoration: none;
  outline: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

mark {
  background-color: inherit;
}

@mixin theme($accentColor, $fontColor, $highlightColor) {
  background-color: $accentColor;
  color: $fontColor;

  mark {
    color: $highlightColor;
  }

  &[data-ui~="secondary"] {
    background-color: transparent;
    color: $accentColor;
    border: 2px solid $accentColor;

    mark {
      background-color: $accentColor;
      color: $fontColor;
    }
  }
}

[data-ui~="primary"] {
  @include theme($primary-color, $primary-font-color, $primary-highlight-font-color);

  &[data-ui~="secondary"] a {
    color: $warn-color;
  }

  &:not([data-ui~="secondary"]) {
    a {
      color: $primary-highlight-font-color;
      text-decoration: underline;
    }
  }
}

[data-ui~="accent1"] {
  @include theme($accent1-color, $accent1-font-color, $accent1-highlight-font-color);
}

[data-ui~="accent2"] {
  @include theme($accent2-color, $accent2-font-color, $accent2-highlight-font-color);
}

[data-ui~="content"] {
  @include theme($content-color, $content-font-color, $content-highlight-font-color);
}

[data-ui~="dark"] {
  @include theme($dark-color, $dark-font-color, $dark-highlight-font-color);
}

[data-ui~="warn"] {
  @include theme($warn-color, $warn-font-color, $warn-highlight-font-color);
}

[data-ui~="purple-cyan-gradient"] {
  background: $purple-cyan-gradient;
}

[data-ui~="cyan-green-gradient"] {
  background: $cyan-green-gradient;
}

[data-ui~="green-yellow-gradient"] {
  background: $green-yellow-gradient;
}

[data-ui~="yellow-orange-gradient"] {
  background: $yellow-orange-gradient;
}

[data-ui~="orange-magenta-gradient"] {
  background: $orange-magenta-gradient;
}

[data-ui~="magenta-purple-gradient"] {
  background: $magenta-purple-gradient;
}


.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: #000;
    opacity: 0.85 !important;
  }
}
