/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import '../../components/node_modules/@angular/cdk/overlay-prebuilt.css';
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

button[data-ui~=small] {
  padding: 0.5em !important;
}

button {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;
  background-color: #009fe4;
  color: #FFF;
  border: 2px solid #009fe4;
  cursor: pointer;
}
button:hover, button:focus {
  background-color: #7ed8ff;
  border-color: #7ed8ff;
  color: white;
  transition: all 0.3s;
}
button:focus {
  outline: none;
}
button[disabled] {
  background-color: #4484a0;
  border-color: #e6e6e6;
  color: white;
  cursor: not-allowed;
}

button[data-ui~=secondary] {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;
  background-color: transparent;
  color: #009fe4;
  border: 2px solid #009fe4;
  cursor: pointer;
}
button[data-ui~=secondary]:hover, button[data-ui~=secondary]:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 159, 228, 0.5);
  color: rgba(0, 159, 228, 0.5);
  transition: all 0.3s;
}
button[data-ui~=secondary]:focus {
  outline: none;
}
button[data-ui~=secondary][disabled] {
  background-color: rgba(0, 0, 0, 0);
  border-color: #e6e6e6;
  color: #4484a0;
  cursor: not-allowed;
}

button[data-ui~=accent1] {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;
  background-color: #ffee00;
  color: #000;
  border: 2px solid #ffee00;
  cursor: pointer;
}
button[data-ui~=accent1]:hover, button[data-ui~=accent1]:focus {
  background-color: #fff899;
  border-color: #fff899;
  color: #4d4d4d;
  transition: all 0.3s;
}
button[data-ui~=accent1]:focus {
  outline: none;
}
button[data-ui~=accent1][disabled] {
  background-color: #b3ac4d;
  border-color: #e6e6e6;
  color: black;
  cursor: not-allowed;
}
button[data-ui~=accent1][data-ui~=secondary] {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;
  background-color: transparent;
  color: #ffee00;
  border: 2px solid #ffee00;
  cursor: pointer;
}
button[data-ui~=accent1][data-ui~=secondary]:hover, button[data-ui~=accent1][data-ui~=secondary]:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 238, 0, 0.5);
  color: rgba(255, 238, 0, 0.5);
  transition: all 0.3s;
}
button[data-ui~=accent1][data-ui~=secondary]:focus {
  outline: none;
}
button[data-ui~=accent1][data-ui~=secondary][disabled] {
  background-color: rgba(0, 0, 0, 0);
  border-color: #e6e6e6;
  color: #b3ac4d;
  cursor: not-allowed;
}

button[data-ui~=accent2] {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;
  background-color: #00bb7e;
  color: #FFF;
  border: 2px solid #00bb7e;
  cursor: pointer;
}
button[data-ui~=accent2]:hover, button[data-ui~=accent2]:focus {
  background-color: #55ffc8;
  border-color: #55ffc8;
  color: white;
  transition: all 0.3s;
}
button[data-ui~=accent2]:focus {
  outline: none;
}
button[data-ui~=accent2][disabled] {
  background-color: #38836b;
  border-color: #e6e6e6;
  color: white;
  cursor: not-allowed;
}
button[data-ui~=accent2][data-ui~=secondary] {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;
  background-color: transparent;
  color: #00bb7e;
  border: 2px solid #00bb7e;
  cursor: pointer;
}
button[data-ui~=accent2][data-ui~=secondary]:hover, button[data-ui~=accent2][data-ui~=secondary]:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 187, 126, 0.5);
  color: rgba(0, 187, 126, 0.5);
  transition: all 0.3s;
}
button[data-ui~=accent2][data-ui~=secondary]:focus {
  outline: none;
}
button[data-ui~=accent2][data-ui~=secondary][disabled] {
  background-color: rgba(0, 0, 0, 0);
  border-color: #e6e6e6;
  color: #38836b;
  cursor: not-allowed;
}

button[data-ui~=warn] {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;
  background-color: #f24726;
  color: #FFF;
  border: 2px solid #f24726;
  cursor: pointer;
}
button[data-ui~=warn]:hover, button[data-ui~=warn]:focus {
  background-color: #fbc1b6;
  border-color: #fbc1b6;
  color: white;
  transition: all 0.3s;
}
button[data-ui~=warn]:focus {
  outline: none;
}
button[data-ui~=warn][disabled] {
  background-color: #ad766b;
  border-color: #e6e6e6;
  color: white;
  cursor: not-allowed;
}
button[data-ui~=warn][data-ui~=secondary] {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;
  background-color: #fff;
  color: #f24726;
  border: 2px solid #f24726;
  cursor: pointer;
}
button[data-ui~=warn][data-ui~=secondary]:hover, button[data-ui~=warn][data-ui~=secondary]:focus {
  background-color: white;
  border-color: #fbc1b6;
  color: #fbc1b6;
  transition: all 0.3s;
}
button[data-ui~=warn][data-ui~=secondary]:focus {
  outline: none;
}
button[data-ui~=warn][data-ui~=secondary][disabled] {
  background-color: white;
  border-color: #e6e6e6;
  color: #ad766b;
  cursor: not-allowed;
}

[data-ui~=primary]:not([data-ui~=secondary]) > button {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;
  background-color: transparent;
  color: #FFF;
  border: 2px solid #FFF;
  cursor: pointer;
}
[data-ui~=primary]:not([data-ui~=secondary]) > button:hover, [data-ui~=primary]:not([data-ui~=secondary]) > button:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s;
}
[data-ui~=primary]:not([data-ui~=secondary]) > button:focus {
  outline: none;
}
[data-ui~=primary]:not([data-ui~=secondary]) > button[disabled] {
  background-color: rgba(0, 0, 0, 0);
  border-color: #e6e6e6;
  color: white;
  cursor: not-allowed;
}

[data-ui~=accent1]:not([data-ui~=secondary]) > button {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;
  background-color: transparent;
  color: #000;
  border: 2px solid #000;
  cursor: pointer;
}
[data-ui~=accent1]:not([data-ui~=secondary]) > button:hover, [data-ui~=accent1]:not([data-ui~=secondary]) > button:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
}
[data-ui~=accent1]:not([data-ui~=secondary]) > button:focus {
  outline: none;
}
[data-ui~=accent1]:not([data-ui~=secondary]) > button[disabled] {
  background-color: rgba(0, 0, 0, 0);
  border-color: #e6e6e6;
  color: black;
  cursor: not-allowed;
}

[data-ui~=accent2]:not([data-ui~=secondary]) > button {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;
  background-color: transparent;
  color: #FFF;
  border: 2px solid #FFF;
  cursor: pointer;
}
[data-ui~=accent2]:not([data-ui~=secondary]) > button:hover, [data-ui~=accent2]:not([data-ui~=secondary]) > button:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s;
}
[data-ui~=accent2]:not([data-ui~=secondary]) > button:focus {
  outline: none;
}
[data-ui~=accent2]:not([data-ui~=secondary]) > button[disabled] {
  background-color: rgba(0, 0, 0, 0);
  border-color: #e6e6e6;
  color: white;
  cursor: not-allowed;
}

[data-ui~=warn]:not([data-ui~=secondary]) > button {
  text-align: center;
  padding: 1em 2em;
  font-size: 0.95em;
  margin: 0.25em;
  box-sizing: border-box;
  border-radius: 0;
  background-color: transparent;
  color: #FFF;
  border: 2px solid #FFF;
  cursor: pointer;
}
[data-ui~=warn]:not([data-ui~=secondary]) > button:hover, [data-ui~=warn]:not([data-ui~=secondary]) > button:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s;
}
[data-ui~=warn]:not([data-ui~=secondary]) > button:focus {
  outline: none;
}
[data-ui~=warn]:not([data-ui~=secondary]) > button[disabled] {
  background-color: rgba(0, 0, 0, 0);
  border-color: #e6e6e6;
  color: white;
  cursor: not-allowed;
}

input {
  color: #000;
  border: 2px solid #e6e6e6;
  padding: 0.5em 0.5em;
  font-size: 15px;
  letter-spacing: 0.4px;
  background-image: none;
  box-shadow: none;
}
input:hover, input:focus {
  border: 2px solid #009fe4;
  outline: none;
}
input::placeholder {
  font-size: 15px;
}

[data-ui~=dark] input {
  background-color: #353535;
  color: #FFF;
  border: none;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0.5em 3.9em 0.5em 0.5em;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
}
select::-ms-expand {
  display: none;
}

.input-label {
  position: relative;
  margin-top: 10px;
}
.input-label label {
  position: absolute;
  top: -10px;
  left: 6px;
  font-size: 11px;
  letter-spacing: 0.4px;
  line-height: 20px;
  padding: 0 0.2em;
  background-color: #fff;
}

.datalistWrapper {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  margin-top: 10px;
  border: 2px solid #e6e6e6;
}
.datalistWrapper label {
  position: absolute;
  top: -10px;
  left: 6px;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 20px;
  padding: 0 0.2em;
  background-color: #fff;
}
.datalistWrapper:hover, .datalistWrapper:focus {
  border: 2px solid #009fe4;
  outline: none;
}
.datalistWrapper > input {
  border: none;
}
.datalistWrapper > input:hover, .datalistWrapper > input:focus {
  border: none;
}

.customSelect {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  margin-top: 10px;
  min-width: 15ch;
  border: 2px solid #e6e6e6;
  color: #000;
  font-size: 15px;
  line-height: 1.5em;
  cursor: pointer;
  background-color: #fff;
}
.customSelect label {
  position: absolute;
  top: -10px;
  left: 6px;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 20px;
  padding: 0 0.2em;
  background-color: #fff;
}
.customSelect:hover, .customSelect:focus {
  border: 2px solid #009fe4;
  outline: none;
}
.customSelect select, .customSelect::after {
  grid-area: select;
}
.customSelect::after {
  position: absolute;
  right: 6px;
  content: "";
  justify-self: end;
  width: 0.8em;
  height: 0.5em;
  background-color: #353535;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}
.customSelect.open::after {
  transform: rotate(180deg);
}

select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid #009fe4;
  border-radius: inherit;
}

[data-ui~=dark] .customSelect {
  background-color: #353535;
  border: none;
}
[data-ui~=dark] .customSelect::after {
  background-color: #fff;
}

.customCheckbox {
  display: flex;
}
.customCheckbox [type=checkbox]:checked ~ label {
  border: 2px solid #009fe4;
  background: #009fe4;
  color: #fff;
}
.customCheckbox label {
  border: 2px solid #e6e6e6;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: #000;
  cursor: pointer;
}
.customCheckbox label:hover {
  border: 2px solid #009fe4;
  background: #009fe4;
  color: #fff;
}
.customCheckbox .visually-hidden {
  position: absolute;
  left: -100vw;
}

[data-ui~=dark] select {
  background-color: #353535;
  color: #FFF !important;
}

[data-ui~=vertical] > section, section {
  margin-top: 0;
  margin-right: 1em;
  margin-bottom: 0em;
  margin-left: 1em;
  padding: 1em;
  overflow: auto;
}
[data-ui~=vertical] > section:first-of-type, section:first-of-type {
  margin-top: 1em;
  margin-left: 1em;
}

[data-ui~=horizontal] > section {
  margin-top: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  padding: 1em;
}
[data-ui~=horizontal] > section:first-of-type {
  margin-top: 1em;
  margin-left: 1em;
}

/* If multilevel sections shall distributed equally: */
section[data-ui~=flatted] {
  padding: 0;
}

section[data-ui~=grouped] {
  padding: 0;
}

[data-ui~=vertical] > section + section[data-ui~=vertical][data-ui~=flatted] > section {
  margin-top: 0;
}

[data-ui~=horizontal] > section + section[data-ui~=horizontal][data-ui~=flatted] > section {
  margin-left: 0;
}

[data-ui~=vertical] > section[data-ui~=flatted][data-ui~=vertical] {
  margin-bottom: 0;
}

[data-ui~=horizontal] > section[data-ui~=flatted][data-ui~=horizontal] {
  margin-right: 0;
}

[data-ui~=horizontal] > section[data-ui~=flatted][data-ui~=vertical] section {
  margin-left: 0;
  margin-right: 0;
}

[data-ui~=vertical] > section[data-ui~=flatted][data-ui~=horizontal] section {
  margin-top: 0;
  margin-bottom: 0;
}

html, body, app-root {
  height: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Aktiv Grotesk, Arial, Sans-Serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.4px;
  background-color: #f6f6f6;
  color: #000;
}
body[data-ui~=dark-mode] {
  background-color: #1a1a1a;
}

input {
  box-sizing: border-box;
}

a {
  color: #009fe4;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}

mark {
  background-color: inherit;
}

[data-ui~=primary] {
  background-color: #009fe4;
  color: #FFF;
}
[data-ui~=primary] mark {
  color: #ffee00;
}
[data-ui~=primary][data-ui~=secondary] {
  background-color: transparent;
  color: #009fe4;
  border: 2px solid #009fe4;
}
[data-ui~=primary][data-ui~=secondary] mark {
  background-color: #009fe4;
  color: #FFF;
}
[data-ui~=primary][data-ui~=secondary] a {
  color: #f24726;
}
[data-ui~=primary]:not([data-ui~=secondary]) a {
  color: #ffee00;
  text-decoration: underline;
}

[data-ui~=accent1] {
  background-color: #ffee00;
  color: #000;
}
[data-ui~=accent1] mark {
  color: #00bb7e;
}
[data-ui~=accent1][data-ui~=secondary] {
  background-color: transparent;
  color: #ffee00;
  border: 2px solid #ffee00;
}
[data-ui~=accent1][data-ui~=secondary] mark {
  background-color: #ffee00;
  color: #000;
}

[data-ui~=accent2] {
  background-color: #00bb7e;
  color: #FFF;
}
[data-ui~=accent2] mark {
  color: #ffee00;
}
[data-ui~=accent2][data-ui~=secondary] {
  background-color: transparent;
  color: #00bb7e;
  border: 2px solid #00bb7e;
}
[data-ui~=accent2][data-ui~=secondary] mark {
  background-color: #00bb7e;
  color: #FFF;
}

[data-ui~=content] {
  background-color: #FFF;
  color: #000;
}
[data-ui~=content] mark {
  color: #ff7f41;
}
[data-ui~=content][data-ui~=secondary] {
  background-color: transparent;
  color: #FFF;
  border: 2px solid #FFF;
}
[data-ui~=content][data-ui~=secondary] mark {
  background-color: #FFF;
  color: #000;
}

[data-ui~=dark] {
  background-color: #000;
  color: #FFF;
}
[data-ui~=dark] mark {
  color: #00bb7e;
}
[data-ui~=dark][data-ui~=secondary] {
  background-color: transparent;
  color: #000;
  border: 2px solid #000;
}
[data-ui~=dark][data-ui~=secondary] mark {
  background-color: #000;
  color: #FFF;
}

[data-ui~=warn] {
  background-color: #f24726;
  color: #FFF;
}
[data-ui~=warn] mark {
  color: #ffee00;
}
[data-ui~=warn][data-ui~=secondary] {
  background-color: transparent;
  color: #f24726;
  border: 2px solid #f24726;
}
[data-ui~=warn][data-ui~=secondary] mark {
  background-color: #f24726;
  color: #FFF;
}

[data-ui~=purple-cyan-gradient] {
  background: linear-gradient(90deg, #7d55c7 0%, #009fe4 100%);
}

[data-ui~=cyan-green-gradient] {
  background: linear-gradient(90deg, #009fe4 0%, #00bb7e 100%);
}

[data-ui~=green-yellow-gradient] {
  background: linear-gradient(90deg, #00bb7e 0%, #ffee00 100%);
}

[data-ui~=yellow-orange-gradient] {
  background: linear-gradient(90deg, #ffee00 0%, #ff7f41 100%);
}

[data-ui~=orange-magenta-gradient] {
  background: linear-gradient(90deg, #ff7f41 0%, #e6007c 100%);
}

[data-ui~=magenta-purple-gradient] {
  background: linear-gradient(90deg, #e6007c 0%, #7d55c7 100%);
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: #000;
  opacity: 0.85 !important;
}